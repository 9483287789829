$primary-color: #067ddf;
$secondary-color: #1565c0;
$black-color: #000000;
$white-color: #ffffff;
$grey_icon-color: rgba(0,0,0,.54);

$badge-color: #0FD30F;

$gray-color: gray;


// successColor
$succes-color: #097d1a;
// failureColor
$failure-color: #e32d2d;
