@import "../../global.scss";

// ============================ button1 =============================
// normal (main == false)
.button1{
    width: 100%;
    min-width: 105px;
    padding: 8px 16px;
    border-radius: 25px;
    border-width: 0px;
    margin: 10px;
    font-size: 14px;
    font-weight: 500;

    color: $black-color;
    background-color: $white-color;
      
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
    user-select: none;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
}

.button1--disabled{
    background-color: #0000001F;
    color: #00000042;
    box-shadow: none;
    cursor: auto;
}

//  black_BTN (main == true)
.button1_black {
    width: 100%;
    min-width: 105px;
    padding: 8px 16px;
    border-radius: 25px;
    border-width: 0px;
    margin: 10px;
    font-size: 14px;
    font-weight: 500;

    color: $white-color;
    background-color: #171717;
      
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
    user-select: none;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
}

.button1_black--disabled{
    background-color: #0000001F;
    color: #00000042;
    box-shadow: none;
    cursor: auto;
}


// ============================ loading button =============================
// normal (main == false)
.loadingBtn{
    width: 100%;
    min-width: 105px;
    padding: 8px 16px;
    border-radius: 25px;
    border-width: 0px;
    margin: 10px;
    font-size: 14px;
    font-weight: 500;

    color: $black-color;
    background-color: $white-color;
      
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
    user-select: none;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
}

.loadingBtn--disabled {
    background-color: #0000001F;
    color: #00000042;
    box-shadow: none;
    cursor: auto;
}

//  black_BTN (main == true)
.loadingBtn_black{
    width: 100%;
    min-width: 105px;
    padding: 8px 16px;
    border-radius: 25px;
    border-width: 0px;
    margin: 10px;
    font-size: 14px;
    font-weight: 500;

    color: $white-color;
    background-color: #171717;
      
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
    user-select: none;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
}

.loadingBtn_black--disabled {
    background-color: #0000001F;
    color: #00000042;
    box-shadow: none;
    cursor: auto;
}

//  laoding_BTN
.loadingBtn--enabled{
    padding: 6px 16px 3px 16px;
}