@import "../../global.scss";


.custom-input {
  position: relative;
  height: 40px;
  display: flex;
  align-items:flex-end;
}

.custom-input-erroMsg{
  font-size: 13px;
  font-weight: 500;
  color: rgb(252, 25, 25);
  margin-left: 30px;
  padding-top: 3px;
}

.input__icon{
  position: absolute;
}

.input__iconEnd{
  position: absolute;
  right: 0;
}

.input {
  background: transparent;
  border: none;
  border-bottom: solid 2px #ccc;
  padding: 5px;
  transition: padding 0.3s;
  width: 100%;

  color: black;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
}

.input-disabled{
  background: transparent;
  border: none;
  border-bottom: solid 2px #ccc;
  padding: 5px;
  transition: padding 0.3s;
  width: 100%;

  color: grey;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
}

.input-red{
  background: transparent;
  border: none;
  border-bottom: solid 2px rgb(252, 25, 25);
  padding: 5px;
  transition: padding 0.3s;
  width: 100%;

  color: black;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
}


.input:placeholder-shown + .label {
  color: black;
  font-size: 14px;
  left: 30px;
  top: 16px;
}
.label,
input:-webkit-autofill + .input:focus + .label{
  // color: $primary-color;
  font-size: 11px;
  font-weight: 600;
  left: 30px;
  pointer-events: none;
  position: absolute;
  top: 0px;
  transition: top 0.3s, left 0.3s, font-size 0.3s;
}

.input::placeholder{
  color: transparent;
}
.input:focus,
.input:not(:placeholder-shown) {
  // border-bottom: solid 2px $primary-color;
  font-weight: 600;
  outline: none;
  padding: 20px 5px 5px;
  padding-left: 30px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill{
  -webkit-text-fill-color: black !important;
}