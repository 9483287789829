@import "../../global.scss";

.topBarContainer{
  width: 100%;
  display: flex;
  justify-content: center;
  // background-color: #fff;
  // background: rgba(255, 255, 255, 0.3);
  // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  // backdrop-filter: blur(60px);
}

.topBar{
    width: 1110px;
    min-width: 300px;
    height: 85px;
    margin: auto;
    padding: 16px;
}

.background{
    height: 740px;
    margin-bottom: 90px;
    place-content: center;
    align-items: center;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;



    // background-color: #F6F8FA;
    // background-image: linear-gradient(270deg,#5DCBDF,#c032db);
    // background: #c0c0aa;  /* fallback for old browsers */
    //   background: -webkit-linear-gradient(to right, #5eaaaf, #c0c0aa);  /* Chrome 10-25, Safari 5.1-6 */
      // background: linear-gradient(to right, #8ad4da, #cacab4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    // background-image: linear-gradient(270deg,#d1467b,#c032db);
}

.notPermit__content{
  margin: 20px; 
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
  background: rgba(255, 255, 255, 0.86);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13.7px);
  -webkit-backdrop-filter: blur(13.7px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.video-background { /* class name used in javascript too */
  // width: 50vw;
  // object-fit: cover;
  // height: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  /* width needs to be set to 100% */
  // height: 100%; /* height needs to be set to 100% */
  position: absolute;
  right: 0;
  bottom: 10px;
  z-index: -1;
  
  // backdrop-filter: blur(50px);
  // background: linear-gradient(to right, #8ad4da, #cacab4);
}

.login__window{
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
    position: relative;
    padding: 40px 24px 40px 24px;
    width: 600px;
    min-width: 200px;
    // color: rgba(0,0,0,.87);

    color: rgba(0,0,0,.87);


    margin: 20px;

    background: rgba(255, 255, 255, 0.3);
    border-radius: 30px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.425);
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
}

.login__title{
    // color: rgba(0,0,0,.87);
    color: black;

    text-align: center;
    font-size: 1.2rem!important;
    font-family: Roboto,sans-serif!important;
    font-weight: 500!important;
    margin: 0!important;
    padding: 0!important;
    letter-spacing: normal;
}

.login__input{
    margin: 30px 10px 30px 10px;
    min-width: 200px;
}  


// ======= loader ========
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #cef;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #cef transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }



  // ------------------- menu icon (left of userName) ----------------
.menuItem{
  width: 80px;
  height: 80px;
  padding: 10px 10px 5px 10px;
  margin: 2px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.2s;
  border-radius: 15px;
  font-weight: map-get($font-weight, subBold );
}

.menuItem:hover{
  // background-color: rgba(255, 71, 71, 0.100);
  background-color: #e4e4e4;
}

.menuItem__selected{
  width: 80px;
  height: 80px;
  padding: 10px 10px 5px 10px;
  cursor: auto;
  margin: 2px;
  text-align: center;
  transition: background-color 0.2s;
  border-radius: 15px;
  font-weight: map-get($font-weight, subBold );
  background-color: #e4e4e4;
  // background-color: rgba(255, 71, 71, 0.205);
}


// ------------------- administration menuItems--------------------
.adminMenuItem{
  color : $gray-color;
  padding: 14px 0px 14px 14px;
  max-height: 48px;
  font-size: map-get($font-size, sm );
  cursor: pointer;
}

.adminMenuItem-selected{
  color: $black-color;
  padding: 14px 0px 14px 14px;
  max-height: 48px;
  font-size: map-get($font-size, sm );
  font-weight: map-get($font-weight, bold);
  cursor: pointer;

  background-color: #f1f1f1;
}

// ------------------- custome icons --------------------
.customIcon{
  width: 35px;
  margin-bottom: 10px;
}
  
// ------------------- select input of country ----------
.select-input{
  margin-top: 7px;
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.95rem !important;
  color: black;
  width: 220px;
  font-weight: 500;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #b7b7b7;
}



// ------------------ city dropDown ---------------------
.city__dropDown{
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 200px;
  max-height: 500px;
  background-color: white;
  top: 40px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.425);
  overflow: auto;
  border-radius: 6px;
  padding: 7px 0px 8px 0px;
  margin-left: 20px;
}

.city__dropDown--item{
  font-weight: 500;
  padding: 5px 5px 5px 10px;
}

.city__dropDown--highlight{
  background-color: rgba(255, 71, 71, 0.205);
}

.city__dropDown--item:hover{
  background-color: rgb(223, 223, 223);
  cursor: pointer;
}

// --------------------- buttons ------------------------
.topbarMain__btn{
  user-select: none;
  cursor: pointer;
  margin: 0;
  // min-width: 64px;
  border-radius: 12px;
  overflow: visible;
  border: 1px solid currentColor;
  padding: 0px;
  line-height: 34px;
  border-color: $black-color;
  box-shadow: 0 0 0 0 rgba(0,0,0,.2),0 0 0 0 rgba(0,0,0,.14),0 0 0 0 rgba(0,0,0,.12);

  background-color: $black-color;
  color: #fff;

  font-weight: 500;
  padding-right: 10px;
}